import checkPermission from './CheckPermission'

export default {
  namespaced: true,
  state() {
    return {
      stock_module: ['master', 'seller', 'editor'],
      categories: ['master', 'editor'],
      composition: ['master', 'seller'],
      colors: ['master', 'seller', 'editor'],
      factor: ['master', 'seller'],
      vendors: ['master'],
      prints: ['master', 'seller'],
      ncm: ['master', 'seller'],
      products: ['master', 'editor'],
      dashboard: ['master', 'seller'],
    }
  },
  getters: {
    showModule(state) {
      return checkPermission(state.stock_module)
    },
    showCategoriesSection(state) {
      return checkPermission(state.categories)
    },
    showCompositionSection(state) {
      return checkPermission(state.composition)
    },
    showColorsSection(state) {
      return checkPermission(state.colors)
    },
    showFactorSection(state) {
      return checkPermission(state.factor)
    },
    showVendorsSection(state) {
      return checkPermission(state.vendors)
    },
    showPrintsSection(state) {
      return checkPermission(state.prints)
    },
    showNcmSection(state) {
      return checkPermission(state.ncm)
    },
    showProductsSection(state) {
      return checkPermission(state.products)
    },
    showDashboardSection(state) {
      return checkPermission(state.dashboard)
    },
  },
}
