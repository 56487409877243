<template>
  <router-view />
</template>

<script>
import './styles/reset.scss'
import './styles/overrides.scss'

export default {
  name: 'App',
}
</script>

<style>
.u-cursor-pointer {
  cursor: pointer;
}
.cke_notifications_area {
  display: none;
}
</style>
