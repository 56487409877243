import checkPermission from './CheckPermission'

export default {
  namespaced: true,
  state() {
    return {
      people_module: ['master', 'seller', 'editor'],
      users: ['master'],
      sellers: ['master'],
      clients: ['master', 'seller'],
      agencies: ['master', 'seller'],
      companies: ['master'],
      search_clients: ['master', 'seller', 'editor'],
      dashboard: ['master', 'seller'],
      dashboard_users_card: ['master'],
      dashboard_sellers_card: ['master'],
      dashboard_clients_card: ['master', 'seller'],
      dashboard_clients_by_seller_card: ['master'],
      dashboard_users_by_profile_card: ['master'],
      profiles: [''],

      delete: {
        agencies: ['master'],
        clients: ['master'],
      },
    }
  },
  getters: {
    showModule(state) {
      return checkPermission(state.people_module)
    },
    showUsersSection(state) {
      return checkPermission(state.users)
    },
    showSellersSection(state) {
      return checkPermission(state.sellers)
    },
    showClientsSection(state) {
      return checkPermission(state.clients)
    },
    showAgenciesSection(state) {
      return checkPermission(state.agencies)
    },
    showCompaniesSection(state) {
      return checkPermission(state.companies)
    },
    showSearchClientsSection(state) {
      return checkPermission(state.search_clients)
    },
    showProfilesSection(state) {
      return checkPermission(state.profiles)
    },
    showDashboardSection(state) {
      return checkPermission(state.dashboard)
    },
    showDashboardUsersCard(state) {
      return checkPermission(state.dashboard_users_card)
    },
    showDashboardSellersCard(state) {
      return checkPermission(state.dashboard_sellers_card)
    },
    showDashboardClientsCard(state) {
      return checkPermission(state.dashboard_clients_card)
    },
    showDashboardClientsBySellerCard(state) {
      return checkPermission(state.dashboard_clients_by_seller_card)
    },
    showDashboardUsersByProfileCard(state) {
      return checkPermission(state.dashboard_users_by_profile_card)
    },
    showDeleteAgencies(state) {
      return checkPermission(state.delete.agencies)
    },
    showDeleteClients(state) {
      return checkPermission(state.delete.clients)
    },
  },
}
