import Vue from 'vue'
import VueCookies from 'vue-cookies'
import VueSession from 'vue-session'
import VueTheMask from 'vue-the-mask'
import App from './App.vue'
import api from './provider/api'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import CKEditor from 'ckeditor4-vue';

Vue.use(VueCookies)
Vue.use(VueSession)
Vue.use(VueTheMask)
Vue.use(CKEditor);
Vue.config.productionTip = false
Vue.prototype.$api = api

api.interceptors.request.use(
  (config) => {
    if (Vue.$cookies.get('token')) {
      config.headers.common.Authorization = `Bearer ${Vue.$cookies.get(
        'token'
      )}`
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

Vue.prototype.$store = store

Vue.filter('formatDate', (value) => {
  if(value){
    return value.slice(0, 10).split('-').reverse().join('/')
  }else{
    return '-'
  }
})

Vue.filter('formatTime', (value) => {
  return value.slice(10)
})

Vue.filter('formatMoney', (value) => {
  if(value){
    return value.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL' })
  }
})

/*
** Menu de contexto global
*/
export const contextMenu = new Vue({
  methods: {
    open(event, item, context) {
      this.$emit('openContextMenu', event, item, context)
    },
    action(type) {
      this.$emit('action', type)
    }
  }
})

/****************************
** Calculo de preço de venda
****************************/
export const calculate = new Vue({
  methods: {
    price(row, print, vendor_table, fixed_value, factor_rows) {

      var qtty = row.quantity
      var print_cost = 0
      var prod_vendor_cost = 0
      // var prod_fixed_cost = 0
      var fixed = fixed_value
      var factor = 0
      var rows = print.rows
      var printed = print.printed ? true : false
      var total = 0

      /***************************************
      ** Limpando valor fixo
      ***************************************/
      if (fixed) {
        if (fixed.includes(',')) {
          fixed = parseFloat(fixed.replace(',', '.'))
        }
      }

      /********************************************
      ** Custo da gravação baseado na quantidade
      ********************************************/
      if (qtty) {
        if (printed) {
          print_cost = rows.find(
            el => qtty >= el.from && qtty <= el.to
          )

          if (print_cost) { print_cost = print_cost.cost }
        } else {
          print_cost = rows.find(
            el => qtty >= el.count_start && qtty <= el.count_end
          )

          if (print_cost) { print_cost = print_cost.amount }
        }
      }

      /***************************************************
      ** Custo do produto recebido do
      ** fornecedor escolhido baseado na quantidade
      ****************************************************/
      if (!printed) {
        prod_vendor_cost = vendor_table.rows.find(
          el => qtty >= el.from && qtty <= el.to
        )

        if (vendor_table.rows[0].fixed_cost) { prod_vendor_cost = vendor_table.rows[0].cost }
        if (prod_vendor_cost) { prod_vendor_cost = prod_vendor_cost.cost }
      }

      /****************************************************************
      ** Valor da porcentagem do fator do produto baseado na quantidade
      *****************************************************************/
      if (qtty) {
        factor = factor_rows.find(
          el => qtty >= el.count_start && qtty <= el.count_end
        )
        if (factor) { factor = factor.percentage }
      }

      /***************************************************
      ** Custo do produto + Custo da gravação
      ****************************************************/
      if (vendor_table.is_fixed) {
        prod_vendor_cost = vendor_table.rows[0].cost
        total = prod_vendor_cost + print_cost;
      } else {
        total = prod_vendor_cost + print_cost;
      }

      /***************************************************
      ** Somando o valor do fixo
      ****************************************************/
      total = parseFloat(total + parseFloat((fixed / qtty).toFixed(2)));

      /***************************************************
      ** Somando a porcentagem do fator
      ****************************************************/
      factor = parseFloat(((factor / 100).toFixed(2) * total).toFixed(2));

      total = parseFloat((total + factor).toFixed(2));

      return total * qtty
    },
  }
})

/*
** Ações em massa global
*/
export const useBudgetEvents = new Vue({
  methods: {
    setBudgetCompany(newvalue) {
      this.$emit('setBudgetCompany', newvalue)
    }
  }
})

/*
** Ações em massa global
*/
export const massAction = new Vue({
  methods: {
    action() {
      this.$emit('action')
    }
  }
})

/*
** Ações em massa global
*/
export const filter = new Vue({
  methods: {
    action(obj) {
      this.$emit('action', obj)
    }
  }
})

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
