import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import '@fortawesome/fontawesome-free/css/all.css';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
      options: {
        customProperties: true,
      },
        themes: {
            light: {
                primary: '#0F5BAA',
                secondary: '#37474F',
                accent: '#0F5BAA',
                error: '#EB4747',
                info: '#FF8A65',
                success: '#54EB47',
                warning: '#EBD047',
                background: '#eee',
                text: '#ccc'
            },
        },
    },
    icons: {
        iconfont: 'fa',
    },
});
