import Vue from 'vue'
import Vuex from 'vuex'
import PeoplePermissions from './modules/PeoplePermissions'
import StockPermissions from './modules/StockPermissions'
import ComercialPermissions from './modules/ComercialPermissions'
import SitePermissions from './modules/SitePermissions'
import checkPermission from './modules/CheckPermission'
import Colors from './modules/Colors'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    hostFront: process.env.VUE_APP_HOST_FRONT,
    hostName: process.env.VUE_APP_HOST_NAME,
    imagePath: process.env.VUE_APP_IMAGE_PATH,
    profile: [],
    showSettings: ['master'],
    isDrawerOpened: false,
    breadcrumbs: [],
    siteMenuDrawer: false,
    fixedValue: null,
    filterDrawer: {
      isOpened: false,
      component: null,
      filterCount: 0,
    },
    productsSearch: {},
    productsToRemove: [],
    modal: {
      show: false,
      component: '',
      text: '',
      caption: null,
      hasObs: false,
      confirm: Function,
      negate: Function,
    },
    budgetClientId: null,
    budgetClientSellerId: null,
    cartDrawer: false,
    client_id: null,
    budgetProducts: [],
    cartProductsIds: [],
    address: {
      city: null,
      state: null,
      district: null,
      street: null,
      complement: null,
      number: null,
    },
    orderTotal: [],
    budgetTotal: [],
    budgetApproval: [],
  },
  getters: {
    getFilterOpened(state) {
      return state.filterDrawer.isOpened
    },
    getFilterComponent(state) {
      return state.filterDrawer.component
    },
    getFilterCount(state) {
      return state.filterDrawer.count
    },
    showSettings(state) {
      return checkPermission(state.showSettings)
    },
  },
  mutations: {
    setFilterDrawer(state, filter) {
      state.filterDrawer = { ...state.filterDrawer, ...filter }
    },
    toggleDrawer(state) {
      state.isDrawerOpened = !state.isDrawerOpened
    },
    setIsAuth(state, value) {
      state.isAuth = value
    },
    setModal(state, obj) {
      state.modal = obj
    },
    setCustomModal(state, obj) {
      state.customModal.show = obj.show
      state.customModal.message = obj.message
      // setTimeout(() => {
      //   (state.customModal.show = false), (state.customModal.message = '')
      // }, 3000)
    },
    setBudgetProducts(state, { product, idx }) {
      state.budgetProducts[idx] = product
    },
    setProductsToRemove(state, obj) {
      if (obj != {}) {
        state.productsToRemove.push(obj)
      }
    },
    resetProducts(state) {
      state.productsToRemove = []
      state.budgetProducts = []
    },
    setCartDrawer(state) {
      state.cartDrawer = !state.cartDrawer
    },
    openSiteMenu(state) {
      state.siteMenuDrawer = !state.siteMenuDrawer
    },
    setProductsSearch(state, obj) {
      state.productsSearch = obj
    },
    setBudgetApproval(state, { value, idx }) {
      console.log(value)
      state.budgetApproval[idx] = value
    },
    setTotalOrder(state, { totalCost, idx }) {
      state.orderTotal[idx] = totalCost
    },
    setTotalBudget(state, { cost, idx }) {
      state.budgetTotal[idx] = cost
    },
    resetTotal(state) {
      state.budgetTotal = []
    },
    resetApproval(state) {
      state.budgetApproval = []
    },
    setNewBudgetClientAndSellerId(state, obj) {
      state.budgetClientId = obj.clientId
      state.budgetClientSellerId = obj.sellerId
    },
    setBreadcrumbs(state, routes) {
      if (routes != null) {
        state.breadcrumbs = [{ title: 'Início', route: '/' }, ...routes]
      } else {
        state.breadcrumbs = [{ title: 'Início', route: '/' }]
      }
      // if(routes != null) {
      //   var breadcrumbs = []
      //   var obj = {}

      //   for (let i = 1; i < routes.length; i++) {
      //       obj.title = routes[i].meta.title
      //       obj.route = routes[i].path
      //       breadcrumbs.push(obj)
      //   }

      //   state.breadcrumbs = [{title: 'Início', route: '/'}, ...breadcrumbs]
      // }else{
      //   state.breadcrumbs = [{title: 'Início', route: '/'}]
      // }
    },
    setCartProductsIds(state, id) {
      state.cartProductsIds.push(id)
    },
    removeCartProductsId(state, id) {
      let idx = state.cartProductsIds.indexOf(id)
      state.cartProductsIds.splice(idx, 1)
    },
    setStateClient(state, value) {
      state.client_id = value
    },
    setProfile(state, value) {
      state.profile = []
      state.profile.push(value)
    },
    // setAddress(state, newaddress) {
    //   ;(state.address.city = newaddress.city),
    //     (state.address.state = newaddress.state),
    //     (state.address.district = newaddress.district),
    //     (state.address.street = newaddress.address_name)
    // },
    setFixedValue(state, value) {
      state.fixedValue = value
    },
  },
  modules: {
    people: PeoplePermissions,
    stock: StockPermissions,
    comercial: ComercialPermissions,
    site: SitePermissions,
    colors: Colors,
  },
})
